<template>
	<v-container>

		<v-row>
			<v-col	md="4" cols="12" v-for="feature in feature_list" :key="feature.title">
				<v-card
				outlined
				>
					<v-card-title>
						<v-icon left > {{feature.icon}} </v-icon>{{feature.title}}
					</v-card-title>
					<v-card-text class="min_hight">{{feature.description}}</v-card-text>
				</v-card>
			</v-col>


		</v-row>
		
	</v-container>
</template>
<script>
export default {
	data: () => ({
		feature_list:[
			{
				"icon":"mdi-view-dashboard",
				"title":"Dashboard",
				"description":"Get statistics of new branches and deleted branches for the last 4 months.",
			},
			{
				"icon":"mdi-text-search",
				"title":"Search",
				"description":"Search for branches by name,registration date, expiry date, type, status and business activity.",
			},
			{
				"icon":"mdi-poll",
				"title":"Business changes",
				"description":"Number of changes happen between select periods of time, can be filtered by market or get an overview of the global market.",
			},
			{
				"icon":"mdi-briefcase-plus-outline",
				"title":"Activity Stat",
				"description":"Gets the number of business activities added or deleted between two selected dates.",
			},

			{
				"icon":"mdi-chart-timeline-variant-shimmer",
				"title":"Activity Rate",
				"description":"Get number of added or deleted business activity in every month since 2017.",
			},
			{
				"icon":"mdi-chart-timeline-variant-shimmer",
				"title":"Activity Trend",
				"description":"Get non-popular added and deleted business activities for the last two weeks.",
			},

			{
				"icon":"mdi-map-marker",
				"title":"Activity Map",
				"description":"Get location of active business in the map for selected business activity.",
			},

			{
				"icon":"mdi-file-table-box-multiple-outline",
				"title":"Area Activity",
				"description":"get number of business activity per area block for select area.",
			},

			{
				"icon":"mdi-office-building-marker-outline",
				"title":"Opportunity list",
				"description":"Get list of locations where it is possible to find an existing customer base for a specific business activity.",
			},

			{
				"icon":"mdi-alert-box-outline",
				"title":"Unfortunate Attempt",
				"description":"Get a list of locations where a new business started and closed or change its location in a short period of time.",
			},
			{
				"icon":"mdi-filter-variant",
				"title":"Market",
				"description":"List of created markets to be used as a filter in business changes and dashboard.",
			},
		]
	}),

}
</script>
<style scoped>
.feature_list li{
	padding-top: 4px;
	padding-bottom: 4px;
}
.price{
	padding: 16px;
	font-size: 32px;
}
.min_hight{
	min-height: 85px;
}
</style>