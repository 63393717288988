<template>
	<v-container >
		<h2>Branch Search</h2>
		<v-row class="">
			<v-col cols="12" md="4">
				
				<div class="d-flex flex-column mb-6">
					<v-card
					
					class="mb-1 "
					outlined
					>
					<v-card-title>
						Search options
					</v-card-title>
					<v-card-text>

						<p>Branch Name</p>
						<v-text-field
						v-model="name_en"
						label="English Name"
						clearable
						outlined
						dense
						></v-text-field>
				
						<v-text-field
						v-model="name_ar"
						label="Arabic Name"
						clearable
						outlined
						dense
						></v-text-field>

						<v-divider></v-divider>

						<p class="pt-4">Branch Status and Type</p>
						<v-select
						v-model="cr_status_selected"
						:items="cr_status_items"
						item-value="value"
						item-text="text"
						clearable
						attach
						
						dense
						outlined

						label="Select Status"
						multiple

						></v-select>
						<v-select
						v-model="cr_type_selected"
						:items="cr_type_items"
						item-value="value"
						item-text="text"
						clearable
						attach
						dense
						outlined
						label="Select Type"
						multiple
						></v-select>
						
						<v-divider></v-divider>

						<p class="pt-4">Branch Date</p>
						<v-row >
							<v-col>
								<v-select
									dense
									outlined
									:items="condiation_options"
									item-value="value"
									item-text="text"
									v-model="reg_date_condiation"
									label="Condition"
								></v-select>
							</v-col>
							<v-col>
								<v-menu
								v-model="menu1"
								:close-on-content-click="false"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="auto"
								>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
									v-model="reg_date"
									label="Registeration Date"
									readonly
									outlined
									clearable
									dense
									v-bind="attrs"
									v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
								v-model="reg_date"
								@input="menu1 = false"
								></v-date-picker>
								</v-menu>
							</v-col>
						</v-row>

						<v-row >
							<v-col>
								<v-select
									dense
									outlined
									:items="condiation_options"
									item-value="value"
									item-text="text"
									v-model="exp_date_condiation"
									label="Condition"
								></v-select>
							</v-col>
							<v-col>
								<v-menu
								v-model="menu2"
								:close-on-content-click="false"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="auto"
								>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
									v-model="exp_date"
									label="Expiry Date"
									readonly
									outlined
									dense
									clearable
									v-bind="attrs"
									v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
								v-model="exp_date"
								@input="menu2 = false"
								></v-date-picker>
								</v-menu>
							</v-col>
						</v-row>
						<v-divider></v-divider>

						<p class="pt-4">Business Activity</p>

						<v-autocomplete
								dense
								outlined
								v-model="selected_activity"
								:items="business_activity_list"
								label="Select Business Activity"
								item-text="name_en"
								item-value="object_id"
								multiple
								
								clearable
							></v-autocomplete>

						<v-btn 
							block 
							color="teal white--text" 
							:loading="loading" 
							v-on:click="search_branch">
							Search
						</v-btn>
						</v-card-text>
					</v-card>


				</div>
			</v-col>
			<v-col md="8" cols="12">
				
				<v-card
				outlined
				ele
			
				>
				<v-card-title>
						Search Result
				</v-card-title>
				<v-card-text>

					<v-data-table
							:page="page"
							:pageCount="numberOfPages"
							:headers="headers"
							:items="branches"
							:options.sync="options"
							:server-items-length="total_branches"
							:loading="loading"
							
						>

						<template v-slot:item.cr_branch_number="{ item }">
							<p class="pt-4 text-left">
								<router-link :to="{path: '/branch/'+item.object_id}">
									{{item.cr_branch_number}}
								</router-link>
							</p>
						</template>
						</v-data-table>
						
					</v-card-text>

				</v-card>
			</v-col>
		</v-row>
		<v-snackbar
			v-model="error_snackbar"
			bottom
			color="error"
			outlined
			right>
			{{ snackbar_error_message }}

		<template v-slot:action="{ attrs }">
			<v-btn
				color="pink"
				text

				v-bind="attrs"
				@click="error_snackbar = false"
				>
				Close
			</v-btn>
		</template>
		</v-snackbar>


		<v-snackbar
			:timeout="2000"
			
			bottom
			color="success"
			outlined
			right
			v-model="success_snakbar">
			{{ snackbar_success_message }}

		<template v-slot:action="{ attrs }">
			<v-btn
				color="teal"
				text

				v-bind="attrs"
				@click="success_snakbar = false"
				>
				Close
			</v-btn>
		</template>
	</v-snackbar>
	</v-container>
</template>

<script>
import {search} from '@/api/app';
import business_json from '@/data/business_activity_list.json';
export default {
	name: 'Search',
	data:function(){
		return {
			error_snackbar: false,
			success_snakbar:false,
			snackbar_error_message:"",
			snackbar_success_message:"",
			selected_activity:[],
			business_activity_list:business_json,
			menu1: false,
			menu2: false,
			name_en:"",
			name_ar:"",
			cr_status_selected:[],
			cr_type_selected:[],
			reg_date:"",
			exp_date: "",
			reg_date_condiation:"less_than",
			exp_date_condiation:"less_than",
			headers: [
					{ text: 'CR-Branch Number', value: 'cr_branch_number',sortable: false,width:100 },
					{ text: 'English Name', value: 'name_en',sortable: false, },
					{ text: 'Arabic Name', value: 'name_ar' ,sortable: false,},
					{ text: 'Status', value: 'status',sortable: false, },

				],
			cr_status_items:[
				{"value":"active","text":"Active"},
				{"value":"active_without_license","text":"Active Without License"},
				{"value":"inactive","text":"Inactive"},
				{"value":"liquidation","text":"Liquidation"},
				],
			cr_type_items:[
				{"text":"Individual Establishment","value":"individual_establishment"},
				{"text":"With Limited Liability Company","value":"with_limited_liability_company"},
				{"text":"Single Person Company","value":"single_person_company"},
				{"text":"Partnership Company","value":"partnership_company"},
				{"text":"Branch of a Foreign Company","value":"branch_of_a_foreign_company"},
				{"text":"Bahrain Shareholding Company (Closed)","value":"bahrain_shareholding_company_closed"},
				{"text":"Exempt Closed Joint Stock Company (Resident)","value":"exempt_closed_joint_stock_company_resident"},
				{"text":"Simple Commandite Company","value":"simple_commandite_company"},
				{"text":"Bahrain Shareholding Company (Public)","value":"bahrain_shareholding_company_public"},
				{"text":"Exempt Closed Joint Stock Company (Non-Resident)","value":"exempt_closed_joint_stock_company_non_resident"},
				{"text":"Specialized Partnership Company","value":"specialized_partnership_company"},
				{"text":"Closed joint Stock Co. (Foreign Capital Co.)","value":"closed_joint_stock_co_foreign_capital_co"},
				{"text":"Commandite By Shares Company","value":"commandite_by_shares_company"},
				{"text":"Audit Firm","value":"audit_firm"},
				{"text":"Investment Limited Partnerships","value":"investment_limited_partnerships"},
				{"text":"Exempt Public Joint Stock Company","value":"exempt_public_joint_stock_company"},
				],
			condiation_options:[
				{"text":"Less than","value":"less_than"},
				{"text":"Greater than","value":"greater_than"},
				{"text":"Equal","value":"equal"}],
			page:1,
			options:{},
			total_branches:0,
			numberOfPages:0,
			branches:[],
			loading:false,

		}
	},
	watch:{
			options:{
				handler(){
					//if (this.selected_activity != null){
						if (this.branches.length !=0){
							this.get_search_result();
						}
					//}
				},
			},
			deep:true,
		},
	methods:{
		search_branch:function(){
			// check if we have search key
			// selected_activity
			// name_en
			// name_ar
			// cr_status_selected
			// cr_type_selected
			// reg_date
			// exp_date
			// if 
			if (this.name_en =="" 
				& this.name_ar==""
				& this.reg_date==""
				& this.exp_date==""
				& this.cr_status_selected.length==""
				& this.cr_type_selected.length==0
				
				& this.selected_activity.length==0){
				this.error_snackbar=true;
				this.snackbar_error_message="Please select search condiations"
				return 
			}
				//if (this.selected_activity !=null){
					this.options.page=1;
					// console.log("Search for ",this.selected_activity);
					this.get_search_result();
				// }else{
				//   console.log("selected activity is empty");
				// }

		},
		get_search_result(){
				this.loading=true;
				const {page,itemsPerPage} =this.options;
				console.log("page: ",page);
				console.log("itemsPerPage: ",itemsPerPage);
				let pageNumber =page-1;

				let data_to_send={
					//'object_ids':this.selected_activity,
					'name_en':this.name_en,
					'name_ar':this.name_ar,
					'status':'ACTIVE',
					'registration_date':this.reg_date,
					'registration_date_condiation':this.reg_date_condiation,
					'expiry_date':this.exp_date,
					'expiry_date_condiation':this.exp_date_condiation,
					'page':pageNumber,
					'cr_status':this.cr_status_selected,
					'cr_type':this.cr_type_selected,
					'page_size':itemsPerPage,
					'activity_object_id_list':this.selected_activity,
				}
				if (this.exp_date !="" && this.exp_date !=null){
						data_to_send.exp_date=this.exp_date
						data_to_send.exp_date_con=this.exp_date_condiation
				}
				console.log("Data to send:",data_to_send);

				search(data_to_send).then((response)=>{
							this.loading=false;
							this.branches=response.data.branches;
							this.total_branches = response.data.total_count;
							this.numberOfPages = response.data.total_pages;
						})

			}


	},
}
</script>

<style scoped>
	.eng_name{
		text-transform: capitalize;

	}
</style>