<template>
	<v-container>
		<v-row>
			<v-col  cols="8" md="4" >
				<h2>Business Changes</h2>
			</v-col>
			<v-spacer></v-spacer>
			<v-col class="text-right" cols="4" md="3" >
				<v-select
				:items="market_list"
				item-text="name"
				item-value="object_id"
				label="Select Market"
				v-model="selected_market_object_id"
				dense
				outlined
				v-on:change="market_change"
				></v-select>
			</v-col>
		</v-row>
		<v-row>
			<v-col md="3" cols="12">
				<v-card
				outlined
				
				>
				<v-card-title>
					Date
				</v-card-title>
				<v-card-text>

					<v-menu
					v-model="menu1"
					:close-on-content-click="false"
					:nudge-right="40"
					transition="scale-transition"
					offset-y
					min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
							v-model="start_date"
							label="Pick start date"
							prepend-icon="mdi-calendar"
							readonly
							outlined
							dense
							v-bind="attrs"
							v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
						color="teal lighten-1"
						:allowed-dates="start_date_allowed_dates"
						v-model="start_date"
						@input="menu1 = false"
						></v-date-picker>
					</v-menu>

					<v-menu
					v-model="menu2"
					:close-on-content-click="false"
					:nudge-right="40"
					transition="scale-transition"
					offset-y
					min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
							v-model="end_date"
							label="Pick end date"
							prepend-icon="mdi-calendar"
							readonly
							outlined
							dense
							v-bind="attrs"
							v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
						color="teal lighten-1"
						:allowed-dates="end_date_allowed_dates"
						v-model="end_date"
						@input="menu2 = false"
						></v-date-picker>
					</v-menu>
						<div class="text-right">
							<v-btn
							:loading="loading"
							large 
							block 
							color="teal white--text" 
							v-on:click="get_data"
							>
							Search
						</v-btn>
					</div>
				</v-card-text>
			</v-card>
			</v-col>
			<v-col md="9" cols="12">

				<v-card
				:loading="loading"
				outlined
				class="pa-4"
				>
				<v-alert
				dense
				transition="slide-y-transition"
				v-model="data_found_alert"
				border="left"
				color="blue"
				type="info"
				>
					No data available.
				</v-alert>

					<apexchart 
						type="bar" 
						height="350" 
						:options="chartOptions" 
						:series="series"></apexchart>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-card
				outlined
				class="pa-4"
				>
					<v-data-table
					:loading="loading"
					:headers="headers"
					:items="online_data"
					:items-per-page="5"
					></v-data-table>
				</v-card>

			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import {get_business_change} from "@/api/app";
export default {
	name: 'BusinessChange',
	data:function (){
		return {
			loading:false,
			data_found_alert:false,
			selected_market_object_id:'',
			start_date: new Date().toISOString().substr(0, 10),
			end_date: new Date().toISOString().substr(0, 10),
			menu1: false,
			menu2: false,
			chart_data:[],
			online_data:[],
			market_list:[],
			headers:[
			{text:"Action",value:"publication_type"},
			{text:"Count",value:"count"},
			],
			series:[],
			chartOptions:{
				colors:["#34495e"],
				chart: {
					type: 'bar',
					height: 350
				},
				plotOptions: {
					bar: {
						horizontal: false,
						
						endingShape: 'rounded'
					},
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent']
				},

				xaxis: {
					type:'category',
					categories: [],
				},
				yaxis: {
					
				},
				fill: {
					opacity: 1
				},
				
			},

		}
	},
	computed: {
		saved_market_list(){return this.$store.getters.market_list}
	},
	methods:{
		start_date_allowed_dates(val){
			//console.log(val);
			return this.end_date>=val
		},
		end_date_allowed_dates(val){
			let today=new Date();
			return (today.toISOString().substr(0,10)>=val) && (val>this.start_date);
		},
		get_data(){
			this.loading=true;
			let data_to_send={
				'start_date':this.start_date,
				'end_date':this.end_date,
				'market_object_id':this.selected_market_object_id}

			get_business_change(data_to_send).then((response)=>{
				console.log(response);
				if (response.error == false){
					let series=[];
					this.online_data=response.data
					console.log("this.online_data: ",this.online_data);
					for (let i=0;i<this.online_data.length;i++){
						
						series.push({
							"y":this.online_data[i].count,
							"x":this.online_data[i].publication_type
						})
					}
					if (this.online_data.length==0){
						this.data_found_alert=true;
					}else{
						this.data_found_alert=false;
					}
					this.series=[{data:series,"name":"Action"}];

					console.log("set series data");
				}else{
					console.log("HTTP error", response.error_message);
				}
				this.loading=false;

			});
		},
		market_change(){
				console.log("Makret change");
				this.market_loading=true;
				for(let i=0;i<this.market_list.length;i++){
					if(this.selected_market_object_id==this.market_list[i].object_id){
						this.selected_market_activity=this.market_list[i].activity_object_id;
						// this.get_dashboard_data(this.market_list[i]);
						// this.market_loading=false;
					}
				}
			},
	},


	mounted:function(){
		//console.log("ENV API URL:",process.env.API_URL);
		//this.myname=process.env.API_URL;
		// add global market to market list
		let today=new Date();
		let month="0"
		if ((today.getMonth()+1) < 10){
			month=month+(today.getMonth()+1);
		}else{
			month=today.getMonth()+1;
		}
		this.start_date= today.getFullYear()+'-'+(month)+'-01';
		
		
		for (let i=0;i<this.saved_market_list.length;i++){
			this.market_list.push(this.saved_market_list[i])
		}
		this.market_list.push({"name":"Global","object_id":"bh_all"})
		//console.log('this.market_list:',this.market_list);
		let default_market_index=0
		for (let i=0;i<this.saved_market_list.length;i++){
			if (Object.prototype.hasOwnProperty.call(this.saved_market_list[i],"default")){
				if (this.saved_market_list[i].default==true){
					default_market_index=i;
					break;
				}
			}
		}
		this.selected_market_object_id=this.market_list[default_market_index].object_id;
		this.selected_market_activity=this.market_list[default_market_index].activity_object_id;
	}

}
</script>