<template>
	<v-container>
		<v-row>
			<v-col  cols="8" md="4" >
				<h2>Dashboard</h2>
			</v-col>
			<v-spacer></v-spacer>
			
			<v-col class="text-right" cols="4" md="3" >
				
				<v-select
				:items="market_list"
				item-text="name"
				item-value="object_id"
				label="Select Market"
				v-model="selected_market_object_id"
				dense
				outlined
				v-on:change="market_change"
				></v-select>

			</v-col>
		</v-row>
		<v-alert
				dismissible
				v-model="top_help"
				type="info"
				color="grey"
				border="left"
				>
					You can change the market from above market menu.
				</v-alert>
		<v-row>
			<v-col cols="12" md="6">
				<v-card
					:loading="market_loading"
					class="pa-2"
					outlined
					elevation="0">
					<v-card-title>
						New Branches
					</v-card-title>

					<v-card-subtitle>
						New branches for {{get_activity(selected_market_activity)}}
					</v-card-subtitle>
					<v-card-text>
						<v-alert
							dense
							transition="slide-y-transition"
							v-model="new_branch_data_found_alert"
							border="left"
							color="blue"
							type="info"
							>
								No data available.
							</v-alert>
						<dashboard-chart 
						chart_title="New Branches"
						:series="new_branches_series"
						:chart_colors="new_chart_colors"></dashboard-chart>
					</v-card-text>
					
					<v-card-title>
						Latest new branches 
					</v-card-title>
					<dashboard-branch-sample
					:sample="new_branch_sample"
					></dashboard-branch-sample>

				</v-card>
			</v-col>
			<v-col cols="12" md="6">
				<v-card
					:loading="market_loading"
					class="pa-2"
					outlined
					elevation="0">
					<v-card-title>
						Deleted Branches
					</v-card-title>
					<v-card-subtitle>
						Deleted branches for {{get_activity(selected_market_activity)}}
					</v-card-subtitle>
					
					<v-card-text>
						<v-alert
							dense
							transition="slide-y-transition"
							v-model="deleted_branch_data_found_alert"
							border="left"
							color="blue"
							type="info"
							>
								No data available.
							</v-alert>
						<dashboard-chart 
						chart_title="Deleted Branches"
						:series="deleted_branches_series"
						:chart_colors="deleted_chart_colors"></dashboard-chart>
					</v-card-text>

					<v-card-title
					>
						Latest deleted branches 
					</v-card-title>

					<dashboard-branch-sample
					:sample="deleted_branch_sample"
					></dashboard-branch-sample>

				</v-card>
			</v-col>

		</v-row>
	</v-container>
</template>
<script>
import business_json from '@/data/business_activity_list.json';
import bahrain_area_block from '@/data/bahrain_area_block.json';
import {get_dashboard} from '@/api/app';
import DashboardChart from '@/components/DashboardChart.vue';
import DashboardBranchSample from '@/components/DashboardBranchSample.vue';
export default {
	name: 'Dashboard',
	components: {DashboardChart,DashboardBranchSample},
	computed: {
			//name(){return this.$store.getters.name+""},
			market_list(){return this.$store.getters.market_list},
			top_help_store(){return this.$store.getters.top_help}
		},

	data:function (){
		return {
			new_branch_data_found_alert:false,
			deleted_branch_data_found_alert:false,
			top_help:true,
			new_chart_colors:["#01a3a4"],
			deleted_chart_colors:["#ee5253"],
			new_branches_series:[],
			deleted_branches_series:[],
			market_loading:false,
			new_branch_sample:[],
			deleted_branch_sample:[],
			market:[],
			selected_market_object_id:'',
			business_activity_list:business_json,
			bahrain_area:bahrain_area_block,
			selected_market_activity:'',
			
			}
		},
		watch:{
			top_help:function(val){
				console.log("top help value: ",val);
				this.$store.commit('hide_help',"dashboard");
			}
		},

		methods:{
		
			get_activity(activity_id){
				for (let i=0;i<this.business_activity_list.length;i++){
					if (this.business_activity_list[i].object_id==activity_id){
						return this.business_activity_list[i].name_en;
					}
				}
				return "Activity name not found!";
			},
			get_area(area_object_id){
				for (let i=0;i<this.bahrain_area.length;i++){
					if (this.bahrain_area[i].object_id==area_object_id){
						return this.bahrain_area[i].name_en;
					}
				}
				return "Area name not found!";
			},
			market_change(){
				console.log("Makret change");
				this.market_loading=true;
				for(let i=0;i<this.market_list.length;i++){
					if(this.selected_market_object_id==this.market_list[i].object_id){
						this.selected_market_activity=this.market_list[i].activity_object_id;
						this.new_branch_data_found_alert=false;
						this.deleted_branch_data_found_alert=false;
						this.new_branch_sample=[]
						this.deleted_branch_sample=[]
						this.new_branches_series=[{	name: 'New Branch',	data:[]}];
						this.deleted_branches_series=[{	name: 'Deleted Branch',	data:[]}];
						this.get_dashboard_data(this.market_list[i]);
						// this.market_loading=false;
					}
				}
			},
			get_dashboard_data(market){
				this.market_loading=true;
				get_dashboard(market).then((response)=>{
				console.log(response);
				this.market_loading=false;
				this.chart_max=0;
				if (response.error==false){
					this.new_branch_sample=response.data.new_branch.sample;
					this.deleted_branch_sample=response.data.deleted_branch.sample;
			
					
					let new_branch_stat=[]
					let deleted_branch_stat=[]
					//let new_branch_category=[]
					let all_zero=true;
					for(let i=0;i<response.data.new_branch.new_branch_stat.length;i++){
						if (response.data.new_branch.new_branch_stat[i].count !=0){
							all_zero=false
						}
						new_branch_stat.push({
							"y":response.data.new_branch.new_branch_stat[i].count,
							"x":response.data.new_branch.new_branch_stat[i].date,
						})
					}
					
					if (all_zero){
						this.new_branch_data_found_alert=true;
					}else{
						this.new_branch_data_found_alert=false;
					}
					all_zero=true;
					for(let i=0;i<response.data.deleted_branch.deleted_branch_stat.length;i++){
						if (response.data.deleted_branch.deleted_branch_stat[i].count !=0){
							all_zero=false
						}
						deleted_branch_stat.push({
							"y":response.data.deleted_branch.deleted_branch_stat[i].count,
							"x":response.data.deleted_branch.deleted_branch_stat[i].date,
						})
					}

					if (all_zero){
						
						this.deleted_branch_data_found_alert=true;
					}else{
						
						this.deleted_branch_data_found_alert=false;
					}
					
					console.log("new_branch_stat:",new_branch_stat);
					this.new_branches_series=[{	name: 'New Branch',	data:new_branch_stat}];
					this.deleted_branches_series=[{	name: 'Deleted Branch',	data:deleted_branch_stat}];
					
									
				}
			});
			}
		},
		mounted:function(){
		console.log("top helper",this.top_help_store.dashboard);
		//console.log("ENV API URL:",process.env.API_URL);
		//this.myname=process.env.API_URL;
		let market_list=this.market_list;
		let default_market_index=0
		for (let i=0;i<market_list.length;i++){
			if (market_list[i].default==true){
				default_market_index=i;
				break;
			}
		}
		if (this.market_list.length >0){
			this.selected_market_object_id=market_list[default_market_index].object_id;
			this.selected_market_activity=market_list[default_market_index].activity_object_id;
			this.get_dashboard_data(this.market_list[default_market_index]);
			this.top_help=this.top_help_store.dashboard;
		}else{
			this.$router.replace({ path: '/add-market' })
		}
		
	},
	created:function(){
		console.log("check market list", this.market_list.length);
		// if (this.market_list.length==0){
		// 	// redirect to add market 
			

		// }

	},
	async activated(){
      //console.log("Activity keep a live");
      // reload the map
      console.log("check market list", this.market_list.length);

    }

}

</script>
