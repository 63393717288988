<template>
	<div class="branch_sample">
		<v-simple-table>
			<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left cr_branch_number_width">
								CR-Branch Number
							</th>
							<th class="text-left">
								Branch Name
							</th>
							<th class="text-left date_width">
								Date
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
						v-for="(branch,i) in sample"
						:key="i"
						>
						<td>
							<router-link :to="{path: '/branch/'+branch.object_id}">
								{{ branch.cr_branch_number }}
							</router-link>
						</td>
						<td>{{ branch.name_en }}</td>
						<td>{{ branch.date }}</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</div>
</template>
<script>
export default {
	name: 'DashboardBranchSample',
	props: ["sample"],

}
</script>
<style scoped>
.cr_branch_number_width{
	width: 100px;
}
.date_width{
	width: 110px;
}
</style>
