<template>
	<v-container>
		<v-row>
			<v-col  cols="8" md="4" >
				<h2>Deleted Activities Stat</h2>
			</v-col>
			
		</v-row>
		<v-row>
			<v-col md="3" cols="12">
				<v-card
				outlined
				
				>
				<v-card-title>Date</v-card-title>
				<v-card-text>

					<v-menu
					v-model="menu1"
					:close-on-content-click="false"
					:nudge-right="40"
					transition="scale-transition"
					offset-y
					min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
							v-model="start_date"
							label="Pick start date"
							prepend-icon="mdi-calendar"
							readonly
							outlined
							dense
							v-bind="attrs"
							v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
						color="teal lighten-1"
						:allowed-dates="start_date_allowed_dates"
						v-model="start_date"
						@input="menu1 = false"
						></v-date-picker>
					</v-menu>

					<v-menu
					v-model="menu2"
					:close-on-content-click="false"
					:nudge-right="40"
					transition="scale-transition"
					offset-y
					min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
							v-model="end_date"
							label="Pick end date"
							prepend-icon="mdi-calendar"
							readonly
							outlined
							dense
							v-bind="attrs"
							v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
						color="teal lighten-1"
						:allowed-dates="end_date_allowed_dates"
						v-model="end_date"
						@input="menu2 = false"
						></v-date-picker>
					</v-menu>
						<div class="text-right">
							<v-btn
							:loading="loading"
							large 
							block 
							color="teal white--text" 
							v-on:click="get_data"
							>
							Search
						</v-btn>
					</div>
				</v-card-text>
			</v-card>
			</v-col>
			<v-col md="9" cols="12">
				<v-card
				:loading="loading"
				outlined
				class="pa-4"
				>
					<v-card-title>Top 7 Result</v-card-title>
					<v-alert
					dense
					transition="slide-y-transition"
					v-model="data_found_alert"
					border="left"
					color="blue"
					type="info"
					>
						No data available.
					</v-alert>
					<activity-stat
					:series="series"
					:chart_colors="chart_colors"
					></activity-stat>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-card
				outlined
				class="pa-4"
				>
				<v-card-title>All Result</v-card-title>
					<v-data-table
					:loading="loading"
					:headers="headers"
					:items="online_data"
					:items-per-page="5"
					></v-data-table>
				</v-card>

			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import {get_deleted_activities_stat} from "@/api/app";
import ActivityStat from '@/components/ActivityStat.vue';
export default {
	name: 'DeletedActivitiesStat',
	components: {ActivityStat},
	data:function (){
		return {
			data_found_alert:false,
			loading:false,
			chart_colors:["#ee5253"],
			start_date: new Date().toISOString().substr(0, 10),
			end_date: new Date().toISOString().substr(0, 10),
			menu1: false,
			menu2: false,
			series:[],
			online_data:[],
			headers:[
			{text:"Activity name",value:"name_en"},
			{text:"Activity Arabic name",value:"name_ar"},
			{text:"Count",value:"count"},
			],
		}
	},
	
	methods:{
		start_date_allowed_dates(val){
			//console.log(val);
			return this.end_date>=val
		},
		end_date_allowed_dates(val){
			let today=new Date();
			return (today.toISOString().substr(0,10)>=val) && (val>this.start_date);
		},
		get_data(){
			this.loading=true;
			let data_to_send={
				'start_date':this.start_date,
				'end_date':this.end_date}
			get_deleted_activities_stat(data_to_send).then((response)=>{
				console.log(response);
				if (response.error == false){
					let series=[]
					this.chart_data=[];
					this.online_data=response.data
					const max_chart_data=7;
					for (let i=0;i<this.online_data.length && i<max_chart_data;i++){
						series.push({"x":this.online_data[i].name_en,"y":this.online_data[i].count});
						}
						this.series=[{"data":series,"name":"Added Activity"}]
						if (this.online_data.length==0){
							this.data_found_alert=true;
						}else{
							this.data_found_alert=false;
						}
					
				}
				this.loading=false;

			});
		},
		
	},


	mounted:function(){
		//console.log("ENV API URL:",process.env.API_URL);
		//this.myname=process.env.API_URL;
	
		let today=new Date();
		let month="0";
		if ((today.getMonth()+1) < 10){
			month=month+(today.getMonth()+1);
		}else{
			month=today.getMonth()+1;
		}
		this.start_date= today.getFullYear()+'-'+(month)+'-01';
		
		
	}

}
</script>