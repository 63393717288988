<template>
	<div class="chart">
		<apexchart ref="chart" type="bar" height="450" :options="chartOptions" :series="series"></apexchart>
	</div>
</template>
<script>
export default {
	name: 'ActivityStat',
	props: ['series','chart_colors'],
	data: function ()
		{
		return {
			
			chartOptions:{
				colors:this.chart_colors,
				chart: {
					type: 'bar',
					height: 350
				},
				plotOptions: {
					bar: {
						horizontal: false,
						
						endingShape: 'rounded'
					},
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent']
				},

				xaxis: {
					type:'category',
					categories: [],
					labels: {
						show:true,
						trim:true,
						

					}
				},
				yaxis: {
					
				},
				fill: {
					opacity: 1
				},
				
			},
		}
	},

	mounted:function(){
		//this.chartOptions.xaxis.categories=this.categories;
	}


}
</script>